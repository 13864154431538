<template>
  <v-container class="">
  <v-row class="mt-0 mt-md-3">
    <v-col class="px-5 px-md-3">
      <v-row>
        <v-col>
          <v-btn plain outlined @click="$router.push('/')">< Zurück</v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col cols="12" md="7">

          <div class="event-details" v-if="event">
            <h1 class="mb-6">{{ event.title }}</h1>
            <h3 v-if="event.description" class="my-4">Beschreibung</h3>
            <p style="white-space: pre-wrap;">{{ event.description }}</p>
            <h3 class="mt-8 mb-4">Kursdetails</h3>
            <table class="event-table">
              <tbody>
              <tr>
                <td>Kursort</td>
                <td v-if="!event.EventLocation.isOnline">
                  {{event.EventLocation.address}}
                </td>
                <td v-else>
                  Onlinekurs
                </td>
              </tr>
              <tr v-if="event.startDate && event.endDate && event.startDate !== '0000-00-00' && event.endDate !== '0000-00-00'">
                <td>Kursdatum von/bis</td>
                <td>{{ formatDate(event.startDate) }} - {{ formatDate(event.endDate) }}</td>
              </tr>
              <tr v-if="event.EventWindows.length > 0">
                <td>Kurszeit</td>
                <td>
                  <table>
                    <tr v-for="window in event.EventWindows" :key="window.id">
                      <td class="py-0 pr-2 font-weight-regular">
                        {{ fullDayStr(window.weekday) }}
                      </td>
                      <td class="pa-0">
                        {{ window.timeStart }} - {{ window.timeEnd }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>Kursniveau</td>
                <td>
                  {{ courseLevelStr(event.courseLevel) }}<span v-if="event.subLevel">{{subLevelStr(event.subLevel)}}</span>
                </td>
              </tr>
              <tr v-if="event.lessonsPerWeek > 0">
                <td>Lektionen pro Woche</td>
                <td>
                  {{ event.lessonsPerWeek }}
                </td>
              </tr>
              <tr v-if="event.lessonCount > 0">
                <td>Lektionen Total</td>
                <td>
                  {{ event.lessonCount }}
                </td>
              </tr>
              <tr v-if="event.totalCosts > 0">
                <td>Preis</td>
                <td>
                  <span v-if="event.multiCourseLink">ab </span>CHF {{ numberWithDelimiter(event.totalCosts) }}
                </td>
              </tr>
              <tr v-if="(event.courseLevel || event.courseLevel === 0) && event.courseLevel <= 5 && event.totalCosts > 0 && event.lessonCount > 0">
                <td>Preis mit Fördergeldabzug
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                      v-on="on"
                      v-bind="attrs"
                      color="#404040"
                      dense
                      class="ml-1"
                      @click="infoSubsidy = !infoSubsidy"
                      >mdi-information-outline</v-icon>
                    </template>
                    <v-card>
                      <v-card-text>
                        Wenn eine Subventionierung bewilligt wird erhält man<br>
                        für diesen Kurs einen Subventionsbetrag von CHF
                        <span v-if="event.courseLevel === 0">{{ SubsidyCalculation.getSubsidyAmountA() }}.-</span>
                        <span v-else>{{ SubsidyCalculation.getSubsidyAmountB() }}.-</span> pro Lektion.<br>
                        <a href="https://www.sg.ch/gesundheit-soziales/soziales/integration/sprache/informationen-deutschfoerderung.html" target="_blank">Weitere Informationen zur Subventionierung</a>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </td>
                <td>
                  CHF {{ numberWithDelimiter(SubsidyCalculation.getCostWithSubsidy(event.totalCosts, event.lessonCount, event.courseLevel)) }}
                </td>
              </tr>
              <tr v-if="event.lessonCount && event.totalCosts && event.lessonCount > 0">
                <td>Kosten pro Lektion</td>
                <td>
                  CHF {{ numberWithDelimiter(Math.floor(event.totalCosts / event.lessonCount)) }}
                </td>
              </tr>
              <tr v-for="tag in event.EventCategories" :key="tag.id">
                <td>
                  {{ tag.name }}
                </td>
                <td>
                  {{ tag.description }}
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </v-col>
        <v-col cols="12" md="5" v-if="event">
          <v-sheet color="white" class="pa-6 pa-md-8" elevation="2" width="100%">
            <v-row>
              <v-col cols="7" md="7">
                <h3 class="mb-6 notranslate">{{ event.EventLocation.Operator.name }}</h3>
                <a v-if="event.EventLocation.Operator.websiteUrl" :href="generateUrl(event.EventLocation.Operator.websiteUrl)" target="_blank">Link zur Webseite</a>
              </v-col>
              <v-col cols="5" md="5" v-if="event.EventLocation.Operator.logo">
                <v-img :src="logoBase + event.EventLocation.Operator.logo" contain :max-height="isMobile() ? 85 : 150" class="mb-1 ml-auto"></v-img>
              </v-col>
            </v-row>
            <p class="mt-3" style="white-space: pre-wrap;">{{ event.EventLocation.Operator.additionalInfo }}</p>
            <h4 class="mt-4">Kontakt</h4>
            <p class="notranslate" v-if="event.EventLocation.contact && !event.EventLocation.isOnline">
              <span v-if="event.EventLocation.contact">{{ event.EventLocation.contact }}<br/></span>
              <span v-if="event.EventLocation.phone">{{ event.EventLocation.phone }}<br/></span>
              <span v-if="event.EventLocation.email">{{ event.EventLocation.email }}</span>
            </p>
            <p v-else class="notranslate">
              <span v-if="event.EventLocation.Operator.contact">{{ event.EventLocation.Operator.contact }}<br/></span>
              <span v-if="event.EventLocation.Operator.phone">{{ event.EventLocation.Operator.phone }}<br/></span>
              <span v-if="event.EventLocation.Operator.email">{{ event.EventLocation.Operator.email }}</span>
            </p>

            <a v-if="event.multiCourseLink && event.externalUrl" :href="generateUrl(event.externalUrl)" target="_blank"><v-btn  block outlined >Kurse anzeigen</v-btn></a>
            <span v-else>
            <a  v-if="event.externalUrl" :href="generateUrl(event.externalUrl)" target="_blank"><v-btn  block outlined >Bei diesem Kurs anmelden<v-icon small class="ml-2">mdi-open-in-new</v-icon></v-btn></a>
            <a  v-else-if="event.EventLocation.Operator.websiteUrl" :href="generateUrl(event.EventLocation.Operator.websiteUrl)" target="_blank"><v-btn block outlined >Bei diesem Kurs anmelden<v-icon small class="ml-2">mdi-open-in-new</v-icon></v-btn></a>
            </span>
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>

import axios from 'axios'
import {SubsidyCalculation} from "../utils/utils";
import {showSnack} from "../globalActions";
// import { showSnack } from '../globalActions'
// import moment from 'moment'

export default {
  name: 'Event',
  computed: {
    SubsidyCalculation() {
      return SubsidyCalculation
    }
  },
  props: ['identifier'],
  components: {
  },
  data () {
    return {
      eventActions: {
        showEventDialog: false,
        confirmDeleteEvent: false
      },
      logoBase: process.env.VUE_APP_AZURE_BLOB_BASE,
      event: null,
      infoSubsidy: false,
      courseLevels: ['A0', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2']
    }
  },
  created () {
  },
  mounted () {
    this.getEvent()
  },
  watch: {
  },
  methods: {
    getEvent () {
      axios
        .get('/events/show/' + this.identifier)
        .then((response) => {
          this.event = response.data
        })
          .catch((ex) => {
            console.log(ex)
            showSnack({message: "Der Kurs konnte nicht geladen werden."})
          })
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
        case 'sm':
          return true
        case 'md':
          return false
        case 'lg':
          return false
        case 'xl':
          return false
      }
    },
    shortDayStr (weekday) {
      return ['', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'][weekday]
    },
    fullDayStr (weekday) {
      return ['', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'][weekday]
    },
    courseLevelStr (level) {
      const levels = level.toString().split(',').map(Number)
      let strLevel = this.courseLevels[levels[0]]
      if (levels.length > 1) {
        // if levels are in succession return levels as from - to (lowest to highest), else return comma-seperated

        if (levels[levels.length - 1] - levels[0] === levels.length - 1) {
          strLevel = strLevel + '-' + this.courseLevels[levels[levels.length - 1]]
        } else {
          levels.forEach((l, i) => {
            if (i > 0) strLevel = strLevel + ',' + this.courseLevels[l]
          })
        }
      }

      return strLevel
    },
    subLevelStr (subLevel) {
      if (subLevel === null || subLevel === '0') {
        return ''
      }

      let levelStr = '/'

      const levels = subLevel.toString().split(',').map(Number)
      levelStr += levels[0]
      if (levels.length > 1) {
        // if levels are in succession return levels as from - to (lowest to highest), else return comma-seperated
        if (levels[levels.length - 1] - levels[0] === levels.length - 1) {
          levelStr += '-' + levels[levels.length - 1]
        } else {
          levels.forEach((l, i) => {
            if (i > 0) {
              l === 5 ? levelStr += ',+' : levelStr += ',' + l
            }
          })
        }
      }

      return levelStr
    },
    numberWithDelimiter (x) {
      if (x < 0) x = 0
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
    },
    formatDate (date) {
      if (!date) return null
      return new Date(date).toLocaleDateString()
    },
    generateUrl (url) {
      if (typeof url === 'string') {
        if (url.indexOf('http') < 0) {
          url = 'https://' + url
        }
        return url
      } else {
        return null
      }
    }
  }

}
</script>
<style lang="scss" scoped>
  h3 {
    color: #404040;
  }
  table.event-table td {
    padding: 15px 0 15px 0;
    color: #404040;

    &:first-child {
      font-weight: bold;
      padding-right: 45px;
      vertical-align: top;
    }
  }

  @media (max-width: 960px) {
    h1 {
      font-size: 1.25rem;
    }

    h3 {
      font-size: 1rem;
    }

    .event-details {
      p {
        font-size: 0.875rem;
      }
    }

    .event-table {
      font-size: 0.875rem;
    }

    .v-btn {
      font-size: 0.875rem;
    }

    .v-sheet {
      font-size: 0.875rem;
    }
  }

  $primary: #008334;
  $primary-alpha: rgba(0, 131, 52, 0.5);
</style>
